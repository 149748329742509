/** 我的订单 */
<template>
  <div class="my-order">
    <el-table :data="orders" border :height="$isPc ? '630px' : '100%'" v-el-table-infinite-scroll="load"
      v-loading="orderLoading">
      <el-table-column prop="product_name" label="产品名称"> </el-table-column>
      <el-table-column prop="created_at" label="申请日期"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm title="确定删除改订单吗？" @confirm="delOrder(scope.$index, orders, scope.row.id)">
            <el-button type="danger" size="mini" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <Page v-if="$isPc" :total="pageTotal" @page="changePage" />
  </div>
</template>

<script>
import { getOrder, delOrder } from '@/api/my'
import Page from '@/components/Page.vue'
export default {
  name: 'FinanceOrder',
  components: {
    Page
  },
  data () {
    return {
      orders: [],
      pageTotal: 0,
      page: 1,
      orderLoading: false,
      finished: false, // 是否加载完成
    }
  },
  mounted () {
    if (this.$isPc) {
      this.getOrder(this.page)
    }
  },
  methods: {
    async load () {
      if (!this.$isPc && !this.finished && !this.orderLoading) {
        await this.getOrder(this.page)
        if (this.orders.length >= this.pageTotal) {
          this.finished = true
          return
        }
        this.page += 1
      }
    },
    async getOrder (page) {
      this.orderLoading = true
      try {
        let { data, total } = await getOrder(page)
        this.orders = this.$isPc ? data : [...this.orders, ...data]
        this.pageTotal = total
      } catch (error) { }
      this.orderLoading = false
    },
    // 切换分页
    changePage (page) {
      this.getOrder(page)
    },
    // 删除订单
    async delOrder (index, rows, id) {
      try {
        if (!this.$store.getters.userToken) return this.$message.warning('请先登录');
        let { code, msg } = await delOrder(id)
        if (code == 200) {
          this.$message.success(msg);
          rows.splice(index, 1);
        } else {
          this.$message.error(msg);
        }
      } catch (error) { }
    }
  }
}
</script>
<style lang="scss" scoped>
.my-order {
  height: 100%;
}
</style>